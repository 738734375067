<template>
    <div class="text-right">
        Create shipment for marketplace:
        <b-form-select
            v-model="currentMarketplace"
            size="sm"
            class="ml-1"
            style="max-width: 80px;"
            @change="updateMarketplace"
        >
            <b-form-select-option v-for="(marketplace, marketplaceIndex) in amazonMarketplaces" :key="marketplaceIndex"
                                  :value="marketplace.id"
            >
                {{ marketplace.name }}
            </b-form-select-option>
        </b-form-select>
        <b-button class="ml-1" variant="outline-primary" size="sm" @click="createShipment">
            Create shipment
        </b-button>
    </div>
</template>

<script>
import {BFormSelect, BFormSelectOption, BButton} from "bootstrap-vue"

export default {
    name: 'amazon-inventory-table-create-shipment',
    components: {
        BFormSelect,
        BFormSelectOption,
        BButton,
    },
    data() {
        return {
            currentMarketplace: null,
        }
    },
    computed: {
        amazonMarketplaces() {
            const marketplaces = this.$store.getters.getAmazonMarketplaces
            return Object.keys(marketplaces).map(marketplaceId => ({
                id: marketplaceId,
                name: marketplaces[marketplaceId]
            }))
        },
        selectedAmazonMarketplace() {
            return this.$store.getters.getCurrentAmazonMarketplaceId
        },
    },
    watch: {
        selectedAmazonMarketplace(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.currentMarketplace = newValue
            }
        },
    },
    mounted() {
        this.$store.dispatch('setAmazonMarketplaces')
        this.$store.dispatch('loadCurrentAmazonMarketplaceId')
        this.$store.dispatch('setInitialAmazonShipmentCreationStockQuantities')
        this.currentMarketplace = this.selectedAmazonMarketplace
    },
    methods: {
        updateMarketplace() {
            this.$store.dispatch('setCurrentAmazonMarketplaceId', this.currentMarketplace)
        },
        createShipment() {
            this.$router.push({
                name: 'inventory.createAmazonShipment',
            })
        }
    },
}
</script>
