<template>
    <div>
        <b-card>
            <dynamic-table-ag-grid
                ref="inventoryTable"
                table-key="ct_key_inventory_table"
                :endpoint="endpoint"
                :records-per-page="100"
                @interface="getChildInterface"
            >
                <template #customControls>
                    <div v-if="canCreateAmazonShipments">
                        <AmazonInventoryTableCreateShipment />
                    </div>
                </template>
            </dynamic-table-ag-grid>
        </b-card>
    </div>
</template>

<script>
import DynamicTableAgGrid from '../../components/Misc/DynamicTable/DynamicTableAgGrid.vue'
import {
    BCard,
} from 'bootstrap-vue'
import Access from "@/services/Access"
import AmazonInventoryTableCreateShipment from "@/components/Amazon/InventoryTableCreateShipment"

export default {
    name: 'inventory.index',
    components: {
        AmazonInventoryTableCreateShipment,
        DynamicTableAgGrid,
        BCard,
    },
    data() {
        return {
            currentLayout: [],
            childInterface: {
                loadData: () => {}
            },
        }
    },
    computed: {
        canCreateAmazonShipments() {
            return Access.checkAccess('amazon_integration.amazon_shipments', Access.LEVEL_READ)
        },
        endpoint() {
            const extraParams = new URLSearchParams({
                'amazonMarketplaceId': this.currentMarketplaceId,
            })

            return `inventory?${extraParams}`
        },
        currentMarketplaceId() {
            return this.$store.getters.getCurrentAmazonMarketplaceId
        },
    },
    watch: {
        currentMarketplaceId(newId, oldId) {
            if (newId === oldId) {
                return
            }
            this.loadData()
        },
    },
    methods: {
        getChildInterface(childInterface) {
            this.childInterface = childInterface
        },
        loadData() {
            this.childInterface.loadData()
        }
    },
}
</script>
